@use '@angular/material' as mat;
@import '../breakpoints';
@import '../mixins';
@import '../palette';

@mixin outline-override() {
    .mdc-notched-outline {
        .mdc-notched-outline {
            &__leading,
            &__notch,
            &__trailing {
                @content;
            }
        }
    }
}

@mixin notch-outline-override() {
    .mdc-notched-outline {
        .mdc-notched-outline {
            &__notch {
                @content;
            }
        }
    }
}

@mixin leading-outline-override() {
    .mdc-notched-outline {
        .mdc-notched-outline {
            &__leading {
                @content;
            }
        }
    }
}

@mixin trailing-outline-override() {
    .mdc-notched-outline {
        .mdc-notched-outline {
            &__trailing {
                @content;
            }
        }
    }
}

@mixin form-field-width($width) {
    width: $width;

    .mat-mdc-form-field-flex {
        width: 100%;
    }
}

@mixin with-label($transitionY, $transitionX: 0, $paddingTop: 0) {
    &.with-label {
        padding-top: $paddingTop;
        .mat-mdc-floating-label {
            overflow: visible;
            top: 50%;
            left: 0;
        }

        .mdc-floating-label--float-above {
            transform: matrix(0.75, 0, 0, 0.75, $transitionX, $transitionY) !important;
        }
    }
}

@mixin form-field-size($height, $padding) {
    .mat-mdc-form-field-flex {
        height: $height;
    }
    .mat-mdc-form-field-infix {
        padding: $padding !important;
    }
}

@mixin form-field-states($base-color, $hover-color, $focus-color, $disabled-color, $disabled-background-color) {
    &.mat-form-field-appearance-outline {
        .mat-mdc-form-field-flex {
            @include outline-override() {
                transition: $transition-150 border-color;
                border-color: $base-color;
                border-top-width: 1px !important;
                padding-top: 1px !important;
                border-top-style: solid !important;
            }
            @include notch-outline-override() {
                border-right: none;
                border-left: none !important;
                clip-path: none;
            }
            &:hover {
                @include outline-override() {
                    border-color: $hover-color;
                    border-top-width: 1px !important;
                    padding-top: 1px !important;
                    border-top-style: solid !important;
                }
            }
            &:focus-within {
                @include outline-override() {
                    border-color: $focus-color;
                    padding-top: 0 !important;
                    border-top-width: 1px !important;
                    border-bottom-width: 1px !important;
                    border-top-style: solid !important;
                }
                @include leading-outline-override() {
                    border-left-width: 1px !important;
                }
                @include trailing-outline-override() {
                    border-right-width: 1px !important;
                }
            }
        }
        // TODO: Refactor disabled style
        &.mat-form-field-disabled {
            .mat-mdc-form-field-flex {
                @include outline-override() {
                    border-color: $disabled-color;
                    background-color: $disabled-background-color;
                }
                .mat-mdc-form-field-infix {
                    z-index: 1;
                }
            }
        }
    }
}

@mixin wrapped-subscript-message($container-width) {
    .mat-mdc-form-field-error,
    .mat-mdc-form-field-hint {
        width: $container-width;
        white-space: normal;
    }
}

@mixin ca-form-field-theme($theme) {
    .mat-mdc-form-field.ca-form-field {
        .mat-icon {
            line-height: 1 !important;
            font-size: inherit !important;
        }

        .mat-mdc-form-field-flex {
            width: 230px;
        }

        $base-color: mat.get-theme-color($theme, primary, 70);
        $hover-color: mat.get-theme-color($theme, primary, 60);
        $focus-color: mat.get-theme-color($theme, primary, 50);
        $disabled-color: mat.get-theme-color($theme, primary, 70);
        $disabled-background-color: mat.get-theme-color($theme, primary, 95);
        --mdc-outlined-text-field-label-text-color: #{mat.get-theme-color($theme, primary, 10)};
        --mdc-outlined-text-field-hover-label-text-color: #{mat.get-theme-color($theme, primary, 10)};
        --mdc-outlined-text-field-focus-label-text-color: #{mat.get-theme-color($theme, primary, 10)};
        @include form-field-states($base-color, $hover-color, $focus-color, $disabled-color, $disabled-background-color);

        &.mat-form-field-invalid:not(.mat-form-field-disabled) {
            $base-color: mat.get-theme-color($theme, error, 60);
            $hover-color: mat.get-theme-color($theme, error, 50);
            $focus-color: mat.get-theme-color($theme, error, 40);
            $disabled-color: mat.get-theme-color($theme, error, 60);
            $disabled-background-color: mat.get-theme-color($theme, error, 90);
            --mdc-outlined-text-field-error-label-text-color: #{mat.get-theme-color($theme, error, 40)};
            --mdc-outlined-text-field-error-hover-label-text-color: #{mat.get-theme-color($theme, error, 40)};
            --mdc-outlined-text-field-error-focus-label-text-color: #{mat.get-theme-color($theme, error, 40)};
            @include form-field-states($base-color, $hover-color, $focus-color, $disabled-color, $disabled-background-color);
        }

        &.mat-form-field-appearance-outline {
            .mat-mdc-text-field-wrapper {
                background: map-get($grey-palette, 100);
                padding: 0 12px 0 12px !important;
            }
        }

        input {
            caret-color: mat.get-theme-color($theme, primary, 50);
            color: mat.get-theme-color($theme, primary, 10);

            &::placeholder {
                color: mat.get-theme-color($theme, primary, 50);
            }
        }

        &.with-wrapped-error-message-350,
        &.with-wrapped-error-message-150,
        &.with-paragraph-error-message,
        &.with-wrapped-error-message,
        &.with-wrapped-hint,
        &.with-hint,
        &.with-error-message {
            .mat-mdc-form-field-subscript-wrapper {
                padding: 0;
                margin-bottom: 5px;
            }
            .mat-mdc-form-field-error-wrapper,
            .mat-mdc-form-field-hint-wrapper {
                padding: 5px 0;
            }

            .mat-mdc-form-field-error,
            .mat-mdc-form-field-hint {
                overflow: visible;
                white-space: nowrap;
            }
        }

        &.with-wrapped-hint {
            @include wrapped-subscript-message(100%);
        }

        &.with-wrapped-error-message {
            @include wrapped-subscript-message(100%);
        }

        &.with-wrapped-error-message-150 {
            @include wrapped-subscript-message(150%);
        }

        &.with-wrapped-error-message-350 {
            @include wrapped-subscript-message(350%);
        }

        @include form-field-size(56px, 26px 0 6px);
        @include with-label(-18);

        &.ca-form-field-m {
            @include form-field-size(48px, 12px 0 8px);
            @include with-label(-44, -10, 25px);
        }

        &.ca-form-field-s {
            @include form-field-size(40px, 8px 0);
            @include with-label(-40, -10, 25px);
        }

        &.ca-form-field-xs {
            @include form-field-size(32px, 4px 0);
            @include with-label(-38, -11, 25px);
        }

        &.ca-form-field-w-full {
            @include form-field-width(100%);
        }
        // TODO: Check slider styles
        &.ca-form-field-slider {
            .mat-mdc-form-field-flex {
                width: 100%;
            }
        }

        &.ca-form-field-short {
            @include form-field-width(180px);
        }

        &.ca-form-field-w-65 {
            @include form-field-width(65px);
        }

        &.ca-form-field-w-77 {
            @include form-field-width(77px);
        }

        &.ca-form-field-w-75 {
            @include form-field-width(75px);
        }

        &.ca-form-field-w-100 {
            @include form-field-width(100px);
        }

        &.ca-form-field-w-44 {
            @include form-field-width(44px);
        }
        &.ca-form-field-w-240 {
            @include form-field-width(240px);
        }

        &.ca-form-field-medium {
            .mat-mdc-form-field-flex {
                height: 48px;
            }
            .mat-mdc-form-field-infix {
                padding: 12px 0 !important;
            }
        }

        &.ca-form-field-value {
            @include outline-override() {
                border-color: mat.get-theme-color($theme, primary, 10);
            }
        }

        &.with-paragraph-error-message {
            .mat-mdc-form-field-error {
                white-space: wrap;
            }
            .mat-mdc-form-field-subscript-wrapper {
                width: 125px;
                @media screen and (min-width: $md) {
                    width: 200px;
                }
            }
        }

        &.ca-highlight:not(.mat-form-field-invalid) {
            &.mat-form-field-appearance-outline {
                .mat-mdc-form-field-flex {
                    @include outline-override() {
                        border-color: map-get($green-palette, 30);
                    }
                    &:hover {
                        @include outline-override() {
                            border-color: map-get($green-palette, 30);
                        }
                    }
                    &:active,
                    &:focus-within {
                        @include outline-override() {
                            border-color: map-get($green-palette, 30);
                        }
                    }
                }

                .mat-mdc-floating-label,
                .mat-icon {
                    color: map-get($green-palette, 30) !important;
                }

                .mat-mdc-form-field-icon-suffix {
                    padding: 16px 0 0 4px;
                }
            }
        }
    }
}

.ca-form-field-location {
    .mat-mdc-form-field {
        box-sizing: border-box;
        width: 23%;

        &.ca-form-field-country-code {
            margin-right: 77%;
        }

        &:nth-of-type(2) {
            width: 77%;
        }

        @media screen and (min-width: $md) {
            width: 30%;

            &.ca-form-field-country-code {
                margin-right: 70%;
            }

            &:nth-of-type(2) {
                width: 70%;
            }
        }

        .mat-mdc-form-field-flex {
            width: 100% !important;
        }
    }
}

.ca-form-field-columns {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    .mat-mdc-form-field {
        @include two-columns(8px, 0px);

        .mat-mdc-form-field-flex {
            width: 100%;
        }
    }
}
