@use '@angular/material' as mat;
@import 'material-system-variables';
@import 'palette';
@import 'typography';
@import 'icons';
@import 'components';
@import 'common';

$light-theme: mat.define-theme(
    (
        color: (
            theme-type: light,
            primary: $red-m3-palette,
            tertiary: $grey-m3-palette,
            use-system-variables: true,
            system-variables-prefix: ca-material
        ),
        typography: $ca-typography-config
    )
);

$dark-theme: mat.define-theme(
    (
        color: (
            theme-type: dark,
            primary: $grey-m3-palette,
            tertiary: $grey-m3-palette,
            use-system-variables: true,
            system-variables-prefix: ca-material
        ),
        typography: $ca-typography-config
    )
);

@mixin custom-components-theme() {
    @include ca-material-system-variables($dark-theme);
    @include ca-icons-theme($light-theme);
    @include ca-table-theme($light-theme);
    @include ca-form-field-theme($dark-theme);
    @include ca-buttons-theme($dark-theme);
    @include ca-toolbar($light-theme);
    @include ca-radio-theme($dark-theme);
    @include ca-accordion-theme($dark-theme);
    @include ca-slider-theme($dark-theme);
    @include ca-checkbox-theme($dark-theme);
    @include ca-select-theme($dark-theme);
    @include ca-snackbar-theme($dark-theme);
    @include ca-spinner-theme($dark-theme);
    @include ca-tooltip-theme($dark-theme);
    @include ca-menu-theme($dark-theme);
    @include ca-datepicker-theme($dark-theme);
    @include ca-dialog-theme($dark-theme);
    @include ca-chips-theme($dark-theme);
    @include ca-carousel-theme($dark-theme);
}

:root {
    @include mat.core();
    @include mat.all-component-themes($dark-theme);
    @include mat.form-field-color($dark-theme);
    @include mat.select-color($dark-theme);
    @include mat.option-color($dark-theme);
    @include mat.button-color($dark-theme);
    @include mat.slider-color($dark-theme);
    @include mat.color-variants-backwards-compatibility($dark-theme);
    @include custom-components-theme();
    @include mat.typography-hierarchy($dark-theme);
    @include ca-typography();
}
